<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Mensaje - Whatsapp Marketing</h4>
            <div class="small text-muted">Administración de mensajes</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Concepto">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col sm="4">
                <b-row>
                  <b-col md="6" class="pr-0">
                    <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="5">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input type="text" size="sm" placeholder="Ingrese un nombre" v-model="filters.name" v-on:keyup.enter.native="load"></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>     
                  <b-col md="6">
                    <v-select :options="arr.filter.status" size="sm" v-model="filters.status" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>  
                </b-row>
              </b-col>
              <b-col sm="2">
                <b-button variant="outline-dark" @click="load()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                     responsive="sm"
                     head-variant="dark"
                     :hover="true"
                     :small="true"
                     :fixed="false"
                     :items="table.items"
                     :fields="table.fields"
                     :filter="table.filter"
                     :current-page="table.currentPage"
                     :per-page="table.perPage" 
                     selectable
                     select-mode="single"
                     @row-selected="onRowSelected"                      
                     v-if="table.items.length">                 

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="6">
                        <table class="table table-hover table-sm">
                          <tbody>
                            <tr v-if="row.item.file">
                              <td class="whatsapp-marketing-message-title-table-custom align-middle">Archvo</td>
                            </tr>
                            <tr v-if="row.item.file">
                              <td class="whatsapp-marketing-message-value-table-custom align-middle">                                
                                <b-link :href="row.item.file" target="_blank">
                                  <b>Ver Archivo</b>
                                </b-link>                                         
                              </td>
                            </tr>
                            <tr>
                              <td class="whatsapp-marketing-message-title-table-custom align-middle">Mensaje</td>
                            </tr>
                            <tr>
                              <td class="whatsapp-marketing-message-value-table-custom align-middle">                                
                                <p v-html="row.item.content" class="whatsapp-marketing-message-content"></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>                
                
                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template> 

               <template v-slot:cell(date)="data">                  
                  <b-icon icon='calendar-event-fill' variant="warning" v-if="!data.item.sent && !data.item.sending && data.item.scheduled"></b-icon>                  
                  {{moment(data.item.date).format('DD/MM/YYYY HH:mm:ss')}}
                </template> 

                <template v-slot:cell(list_id)="data">
                  <div v-if="data.item.list">
                    <b-badge variant="secondary" style="font-size:12px">{{data.item.list.name}}</b-badge>
                  </div>
                </template> 

                <template v-slot:cell(notification_whatsapp_id)="data">
                  <div v-if="data.item.notification_whatsapp">
                    <b-badge variant="secondary" style="font-size:12px">{{data.item.notification_whatsapp.name}}</b-badge>
                  </div>
                </template> 

                <template v-slot:cell(send_messages)="data">
                  <div v-if="data.item.sent && !data.item.sending">
                    <div v-if="data.item.logs">
                      <div v-if="getMenssageSend(data.item.logs, 'total')"> 
                        Enviados: <b>{{getMenssageSend(data.item.logs, 'ok')}}</b> 
                        <span v-if="getMenssageSend(data.item.logs, 'error')" :id="'popover-errores-sending-' + data.item.id">
                          / Errores: <b>{{getMenssageSend(data.item.logs, 'error')}}</b>

                          <b-popover :target="'popover-errores-sending-' + data.item.id" variant="danger" triggers="hover">
                            <template #title>Errores de envío por contacto</template>
                            <div v-html="getErrorSendngForContacts(data.item)"></div>
                          </b-popover>                             
                        </span>
                      </div>
                      <div v-else>
                        <div v-if="data.item.list">                          
                          Enviados: <b>{{data.item.list.cant_contacts}}</b>
                        </div>                           
                      </div>                      
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="data.item.list">
                      <div v-if="data.item.list.cant_contacts>1">
                        Se enviará a <b>{{data.item.list.cant_contacts}}</b> contactos
                      </div>
                      <div v-else>
                        Se enviará a <b>{{data.item.list.cant_contacts}}</b> contacto
                      </div>                                     
                    </div>                       
                  </div>                 
                </template>                 

                <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
                </template> 
        
                <template v-slot:cell(status)="data">                  
                  <div v-if="data.item.queue">
                    <b-spinner small type="grow" ></b-spinner>
                    En Cola...
                  </div>

                  <div v-if="data.item.sending">
                    <b-spinner small type="grow" ></b-spinner>
                    Enviando...
                  </div>

                  <b-badge variant="success" v-if="data.item.sent && !data.item.sending && !data.item.scheduled && !data.item.queue">Enviado</b-badge>
                  <b-badge variant="danger" v-if="!data.item.sent && !data.item.sending && !data.item.scheduled && !data.item.queue">Sin Enviar</b-badge>
                  <b-badge variant="warning" v-if="!data.item.sent && !data.item.sending && data.item.scheduled && !data.item.queue">Programado</b-badge>
                </template> 

                <template v-slot:cell(error)="data">
                  <div :title="data.item.error" v-if="data.item.error && !data.item.sent">
                    <i class='fa fa-question-circle' style='font-size:15px; cursor:pointer;'></i>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right" 
                              v-if="!data.item.sent && !data.item.sending && !data.item.scheduled && !data.item.queue">

                    <b-dropdown-item @click="send(data.item)" v-if="!data.item.scheduled">
                      <i class="fa fa-send whatsapp-marketing-crud-icon"></i> Enviar Ahora
                    </b-dropdown-item>                                         
                    
                    <b-dropdown-item @click="scheduled(data.item)" v-if="!data.item.scheduled">
                      <i class="fa fa-calendar whatsapp-marketing-crud-icon"></i> Programar
                    </b-dropdown-item>                                           

                    <b-dropdown-header>Vista Previa</b-dropdown-header>  
                    <b-dropdown-item @click="openTest(data.item)">
                      <i class="fa fa-eye whatsapp-marketing-crud-icon"></i> Previsualizar
                    </b-dropdown-item>                                                      

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="duplicate(data.item)">
                      <i class="fa fa-copy ml-0 mr-0" style="color:#000"></i> Clonar
                    </b-dropdown-item>
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right" 
                              v-else>                              
                    <b-dropdown-item @click="duplicate(data.item)">
                      <i class="fa fa-copy ml-0 mr-0" style="color:#000"></i> Clonar
                    </b-dropdown-item>
                    <b-dropdown-item @click="cancelScheduled(data.item)" v-if="data.item.scheduled && !data.item.sent && !data.item.sending">
                      <i class="fa fa-calendar-times-o whatsapp-marketing-crud-icon"></i> Cancelar Programación
                    </b-dropdown-item>                          
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="load()" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"              
              header-bg-variant="dark"
              header-text-variant="white"
              size="md"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="12">
                <b-form-group label="Fecha / Hora de Envío">
                  <b-form-input type="datetime-local"
                                size="sm"
                                v-model="crud.form.date"
                                required>
                  </b-form-input>
                </b-form-group>                                              
              </b-col>     

              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre de referencia">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>     

              <b-col md="12">
                <FindObject type="whatsappMarketingList" 
                            @select-object="loadList($event)" 
                            :valueID="crud.form.list_id"
                            :where="conditionListActive"
                            render="search"/>               
              </b-col>

              <b-col md="12">
                <b-form-group label="Instancia Whatsapp">
                  <b-form-select v-model="crud.form.notification_whatsapp_id" :options="arr.select.notificationWhatsapp"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Contenido">
            <b-row>
              <b-col md="12">
                <b-form-group label="Archivo" description="Formato: jpg, png, gif, pdf, mp4">                
                  <b-form-file v-if="!crud.form.fileLink" 
                                v-model="crud.form.file"                             
                                size="sm"
                                accept=".jpg, .png, .gif, .pdf, .mp4"
                                :state="Boolean(crud.form.file)"
                                placeholder="Elija un archivo o sueltelo aquí..."
                                drop-placeholder="Suelta el archivo aquí...">
                  </b-form-file>      
                  <div v-else>
                    <b-link :href="crud.form.fileLink" target="_blank">
                      <b>Ver Archivo</b>
                    </b-link>           
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                      <i class="fa fa-refresh"></i>
                    </b-button>                                         
                  </div>
                </b-form-group>
              </b-col>           

              <b-col md="12">                
                <b-form-group label="Contenido" description="Negrita: *texto* | Cursiva: _texto_ | Tachado: ~texto~ | Monoespaciado: ```texto``` | Nombre Contacto: [name] | Teléfono: [phone] | Campos Personalizados: [custom1]-[custom5]">
                  <b-form-textarea                                
                    v-model="crud.form.content"
                    size="sm"
                    placeholder="Ingresar el contenido"
                    rows="10"
                  ></b-form-textarea>
                </b-form-group>     
                <b-link id="link-button-emojis" href="#" tabindex="0">
                  Emojis
                </b-link>
                <b-popover target="link-button-emojis" title="Emojis" triggers="hover">
                  <b-row>
                    <b-col>
                      <b-tabs>
                        <b-tab active>
                          <template v-slot:title>
                            😀 
                          </template>
                          <div class="whatsapp-marketing-crud-message-containt-emojis">
                            <span v-for="(item, index) in getListEmojis().block1" :key="index" 
                                  :id="'emoji_block1_'+ index"
                                  @click="copyEmojis('block1', index)"
                                  class="whatsapp-marketing-crud-message-emojis">
                              {{item}}
                            </span>    
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            🐵 
                          </template>    
                          <div class="whatsapp-marketing-crud-message-containt-emojis">                  
                            <span v-for="(item, index) in getListEmojis().block2" :key="index" 
                                  :id="'emoji_block2_'+ index"
                                  @click="copyEmojis('block2', index)"
                                  class="whatsapp-marketing-crud-message-emojis">
                              {{item}}
                            </span>                  
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            🌍 
                          </template>    
                          <div class="whatsapp-marketing-crud-message-containt-emojis">                  
                            <span v-for="(item, index) in getListEmojis().block3" :key="index" 
                                  :id="'emoji_block3_'+ index"
                                  @click="copyEmojis('block3', index)"
                                  class="whatsapp-marketing-crud-message-emojis">
                              {{item}}
                            </span>                  
                          </div>
                        </b-tab> 
                        <b-tab>
                          <template v-slot:title>
                            🚮 
                          </template>    
                          <div class="whatsapp-marketing-crud-message-containt-emojis">                  
                            <span v-for="(item, index) in getListEmojis().block4" :key="index" 
                                  :id="'emoji_block4_'+ index"
                                  @click="copyEmojis('block4', index)"
                                  class="whatsapp-marketing-crud-message-emojis">
                              {{item}}
                            </span>                  
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            🇦🇷 
                          </template>    
                          <div class="whatsapp-marketing-crud-message-containt-emojis">                  
                            <span v-for="(item, index) in getListEmojis().block5" :key="index" 
                                  :id="'emoji_block5_'+ index"
                                  @click="copyEmojis('block5', index)"
                                  class="whatsapp-marketing-crud-message-emojis">
                              {{item}}
                            </span>                  
                          </div>
                        </b-tab>                                      
                      </b-tabs>              
                    </b-col>
                  </b-row>
                </b-popover>
              </b-col>                           
            </b-row>        
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal> 

      <!-- CRUD TEST -->
      <b-modal v-model="modal.test.active"              
              header-bg-variant="secondary"
              header-text-variant="black"
              size="md"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>
        <div slot="modal-header">
          {{this.modal.test.title}}
        </div>
                
        <b-row>
          <b-col md="12">
            <b-form-group label="Número Whatsapp">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.test.phone"
                            required
                            placeholder="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
              </b-form-input>
            </b-form-group>               
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.test.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="sendTest()">Enviar Test</b-button>          
        </div>                
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject'
  import Emojis from './inc/emojis'
  import Storage from '@/handler/storageSession'
  
  export default {     
    components: {
      FindObject,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudMessage',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},            
            {key: 'date', label: 'Fecha / Hora de Envío', sortable: true, class: 'align-middle'},       
            {key: 'name', label: 'Nombre', class: 'align-middle'},             
            {key: 'list_id', label: 'Lista', class: 'align-middle' },
            {key: 'notification_whatsapp_id', label: 'Whatsapp', class: 'align-middle' },
            {key: 'status', label: 'Estado', class: 'align-middle' },
            {key: 'error', label: 'Error', class: 'align-middle' },            
            {key: 'send_messages', label: 'Total de Envíos', class: 'align-middle' },                                                        
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,  
        },
        crud: {
          form: {
            id: 0,
            date: '',
            name: '',
            message: '',    
            fileLink: '',
            file: null,  
            list: null,
            list_id: 0,
            notification_whatsapp_id: 0,
          },  
          test: {
            message: null,
            phone: ''
          }        
        },
        modal: {
          form: {
            active: false,
            title: ''
          },    
          test: {
            active: false,
            title: ''
          },                 
        },
        arr: {
          whatsappMarketing: [],
          select : {
            notificationWhatsapp: []
          },
          filter : {
            status: [
              {code:true, label: 'Enviado'},
              {code:false, label: 'Sin Enviar'},                            
            ],
          }
        },
        filters: {          
          name: '', 
          date_start: '',
          date_end: '',
          status: false
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()  
      this.load()
      this.loadNotificationWhatsapp()
    },
    computed: {
      // CONDITIONS SELECT
      conditionListActive(){
        return [{field: 'active', condition: true}];
      },          
    },    
    methods: {
      // TABLE
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.whatsappMarketing.data.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },      
      
      // FILTER
      filterLoad() {
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage()
      },

      // MENSAJE
      load() {
        this.saveFilterStorage()
        var result = serviceAPI.filtrarWhatsappMarketing(this.filters, this.table.currentPage)

        result.then((response) => {          
          var data = response.data
          this.table.items = data.data
          this.table.tablePaginate = data    
          this.arr.whatsappMarketing = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
          }           
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.date = ''
        this.crud.form.name = ''
        this.crud.form.content = ''
        this.crud.form.file = null
        this.crud.form.fileLink = ''
        this.crud.form.list = null
        this.crud.form.list_id = 0        
        this.crud.form.notification_whatsapp_id = 0

        this.modal.form.title = "Nuevo Mensaje"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id

        this.crud.form.date = moment(item.date).format('YYYY-MM-DDTHH:mm')
        this.crud.form.name = item.name
        this.crud.form.content = item.content
        this.crud.form.file = null
        this.crud.form.fileLink = item.file        
        this.crud.form.list = item.list
        this.crud.form.list_id = item.list_id
        this.crud.form.notification_whatsapp_id = item.notification_whatsapp_id

        this.modal.form.title = "Editar Mensaje"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') ' + this.crud.form.name + '?', {
          title: 'Borrar Acceso',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarWhatsappMarketing(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      duplicate(item) {
        this.crud.form.id = 0
        this.crud.form.date = item.date
        this.crud.form.name = item.name
        this.crud.form.content = item.content
        this.crud.form.file = null
        this.crud.form.fileLink = item.file        
        this.crud.form.list = item.list
        this.crud.form.list_id = item.list_id
        this.crud.form.notification_whatsapp_id = item.notification_whatsapp_id

        this.$bvModal.msgBoxConfirm('¿Desea clonar el item ' + this.crud.form.name + '?', {
          title: 'Clonar Mensaje',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var file = new FormData();
            if(this.crud.form.fileLink) {
              file.append("file", this.crud.form.fileLink);
            } else {        
              if(this.crud.form.file) {          
                file.append("file", this.crud.form.file);
              }
            }  

            var result = serviceAPI.agregarWhatsappMarketing(JSON.stringify(this.crud.form), file);

            result.then((response) => {            
              loader.hide()
              this.load()
              this.$awn.success("Mensaje clonado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        if(!this.crud.form.notification_whatsapp_id) {
          this.$awn.alert("Debe seleccionar una instancia de whatsapp")
          return false
        }
        
        let loader = this.$loading.show();

        var file = new FormData();
        if(this.crud.form.fileLink) {
          file.append("file", this.crud.form.fileLink);
        } else {        
          if(this.crud.form.file) {          
            file.append("file", this.crud.form.file);
          }
        }  

        if (this.crud.form.id) {
          var result = serviceAPI.editarWhatsappMarketing(JSON.stringify(this.crud.form), file);
        } else {
          var result = serviceAPI.agregarWhatsappMarketing(JSON.stringify(this.crud.form), file);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadList(object) {
        if(object){
          this.crud.form.list = object
          this.crud.form.list_id = object.id
        } else {
          this.crud.form.list = null
          this.crud.form.list_id = 0
        }
      },
      loadNotificationWhatsapp() {        
        var result = serviceAPI.obtenerNotificationWhatsapp()        
        result.then((response) => {
          var data = response.data    
          
          this.arr.select.notificationWhatsapp = []    

          data.forEach(element => {            
            this.arr.select.notificationWhatsapp.push({ value: element.id, text: element.name })
          });          
        })              
      }, 

      // EMOJIS
      getListEmojis() {
        return {
          block1: Emojis.getEmojisBlock1(),
          block2: Emojis.getEmojisBlock2(),
          block3: Emojis.getEmojisBlock3(),
          block4: Emojis.getEmojisBlock4(),
          block5: Emojis.getEmojisBlock5(),
        }
      },
      copyEmojis(block, index) {    
        var codigoACopiar = document.getElementById('emoji_' + block + "_" + index);
        var seleccion = document.createRange(); 
        seleccion.selectNodeContents(codigoACopiar);

        window.getSelection().removeAllRanges();
        window.getSelection().addRange(seleccion); 

        try {
            var res = document.execCommand('copy');
            if (res){
              this.$awn.success("Emoji copiado en el portapapeles");
            } else {
              this.$awn.alert("No se pudo copiar el Emoji");
            }              
        }
        catch(ex) {
            // error
        }
        window.getSelection().removeRange(seleccion);
      },

      // ENVIO
      send(item){
        this.$bvModal.msgBoxConfirm('¿Desea enviar el mensaje (' + item.name + ')?', {
          title: 'Enviar Mensaje',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'ENVIAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
          
            var result = serviceAPI.sendMessage(item);

            result.then((response) => {                    
              loader.hide()              
              this.$awn.success("Enviando Mensaje");
              setTimeout(()=>{
                this.load()                
              },1000) 
            })
            .catch(error => {
              loader.hide()            
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },

      // PREVIEW
      openTest(item) {
        this.crud.test.message = item
        this.crud.test.phone = ''

        this.modal.test.title = "Test de Envío"
        this.modal.test.active = true
      },
      sendTest(){        
        if(this.crud.test.phone.length < 9 || this.crud.test.phone.length > 20) {
          this.$awn.alert("El nro de télefono no posee un formato válido")
          return false
        }

        let loader = this.$loading.show();
        var data = {
          content: this.crud.test.message.content,
          file: this.crud.test.message.file,
          phone: this.crud.test.phone,
          notification_whatsapp_id: this.crud.test.message.notification_whatsapp_id,
        }
        var result = serviceAPI.sendMessageTest(data);

        result.then((response) => {      
          this.modal.test.active = false        
          loader.hide()
          this.load()
          this.$awn.success("Prueba Enviada");
        })
        .catch(error => {
          loader.hide()
          this.modal.test.active = false 
          this.$awn.alert(Error.showError(error));
        })        
      }, 

      // SCHEDULED
      scheduled(item){      
        let date = moment(item.date).format('DD/MM/YYYY HH:mm:ss')
        this.$bvModal.msgBoxConfirm('¿Desea programar el mensaje (' + item.name + ') para la fecha ' + date + '?', {
          title: 'Programar Mensaje',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'PROGRAMAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
          
            var result = serviceAPI.scheduledMessage(item);

            result.then((response) => {                    
              loader.hide()              
              this.$awn.success("Mensaje Programado");
              this.load()
            })
            .catch(error => {
              loader.hide()            
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      cancelScheduled(item){        
        this.$bvModal.msgBoxConfirm('¿Desea volver a programar el mensaje (' + item.name + ') ?', {
          title: 'Cancelar Programación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'VOLVEAR A PROGRAMAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
          
            var result = serviceAPI.cancelScheduledMessage(item);

            result.then((response) => {                    
              loader.hide()              
              this.$awn.success("Mensaje Programado Cancelado");
              this.load()
            })
            .catch(error => {
              loader.hide()            
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },

      // INFO SNEDING
      getMenssageSend(logs, type) {
        var count = 0
        if(logs) {
          logs.forEach(element => {
            if(type=='ok') {
              if(element.send) {
                count++
              }
            } 

            if(type=='error'){
              if(element.error) {
                count++
              }
            }  
            
            if(type=='total'){              
              count++
            }              
          });
        }
        return count
      },
      getErrorSendngForContacts(item) {
        var error = ''
        if(item.logs) {
          var count = 0
          item.logs.forEach(element => {            
            if(element.error) {
              let name = element.whatsapp_marketing_contact.name
              let phone = element.whatsapp_marketing_contact.phone
              count = count + 1

              error = error + count + ") " + name + ' >> ' + phone + ' >> ' + element.observations + "<br>"              
            }
          });
        }
        return error
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_whatsapp_message', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_whatsapp_message')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_whatsapp_message'))
        }         
      },   
    }    
  }
</script>
<style>  
  .whatsapp-marketing-message-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: left
  }
  .whatsapp-marketing-message-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }  
  .whatsapp-marketing-crud-message-emojis {
    margin-right: 1px;
    font-size: 25px;
    cursor: pointer;
  }
  .whatsapp-marketing-crud-message-containt-emojis {
    height: 250px;
    overflow: auto;
  }
  .whatsapp-marketing-crud-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .whatsapp-marketing-message-content {
    white-space: pre-line;
  }
</style>